export const colours = {
  black: '#000',
  white: '#fff',
  midnight: '#1d1d1b',
  silver: '#a2a2a2',
  cream: '#f4f2f0',
  pastelPink: '#c6aeb0',
  duskyPink: '#80696b',
  pastelBlue: '#e3e8ee',
  royalBlue: '#05192e'
}

export const dropShadow = '0px 12px 24px 0px rgba(0,0,0,0.24)'
